// Buttons

.btn-hover-scale {
  display: inline-block;
  transition: all 300ms ease;

  &:hover {
    transform: scale(.93);
  }

  &.disabled {
    user-select: none;
    pointer-events: none;
    filter: grayscale(0.5) brightness(0.5);
  }
}
.btn-back {
  display: inline-block;
  width: px(267);
  height: px(78);
  line-height: px(78);
  text-align: center;
  margin-right: px(20);
  outline: none;
  color: #001c55 !important;
  font-weight: 500;
  font-style: italic;
  font-size: px(30);
  background: url(/images/btn-white.png) no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
}
