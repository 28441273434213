
@font-face {
  font-family: 'SVN-Gotham';
  src: url('/fonts/iCielGotham-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/iCielGotham-Medium.woff') format('woff'),
    url('/fonts/iCielGotham-Medium.ttf') format('truetype'),
    url('/fonts/iCielGotham-Medium.svg#iCielGotham-Medium') format('svg');
    font-weight: 400;
}

@font-face {
  font-family: 'SVN-Gotham';
  src: url('/fonts/iCielGotham-Thin.eot?#iefix') format('embedded-opentype'),
    url('/fonts/iCielGotham-Thin.woff') format('woff'),
    url('/fonts/iCielGotham-Thin.ttf') format('truetype'),
    url('/fonts/iCielGotham-Thin.svg#iCielGotham-Thin') format('svg');
    font-weight: 300;
}

@font-face {
  font-family: 'SVN-Gotham';
  src: url('/fonts/iCielGotham-Ultra.eot?#iefix') format('embedded-opentype'),
    url('/fonts/iCielGotham-Ultra.woff') format('woff'),
    url('/fonts/iCielGotham-Ultra.ttf') format('truetype'),
    url('/fonts/iCielGotham-Ultra.svg#iCielGotham-Ultra') format('svg');
    font-weight: 500;
}
@font-face {
  font-family: 'Beba';
  src: url('/fonts/UTMBebas.eot?#iefix') format('embedded-opentype'),
    url('/fonts/UTMBebas.woff') format('woff'),
    url('/fonts/UTMBebas.ttf') format('truetype'),
    url('/fonts/UTMBebas.svg#UTMBebas') format('svg');
}
@font-face {
  font-family: 'DINPro';
  src: url('/fonts/DINPro-CondensedMedium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/DINPro-CondensedMedium.woff') format('woff'),
    url('/fonts/DINPro-CondensedMedium.ttf') format('truetype'),
    url('/fonts/DINPro-CondensedMedium.svg#DINPro-CondensedMedium') format('svg');
    font-weight: 400;
}
@font-face {
  font-family: 'DINPro';
  src: url('/fonts/DINPro-CondensedBold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/DINPro-CondensedBold.woff') format('woff'),
    url('/fonts/DINPro-CondensedBold.ttf') format('truetype'),
    url('/fonts/DINPro-CondensedBold.svg#DINPro-CondensedBold') format('svg');
    font-weight: 500;
}
@font-face {
  font-family: 'Oswald-Regular';
  src: url('/fonts/Oswald-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/Oswald-Regular.woff') format('woff'),
    url('/fonts/Oswald-Regular.ttf') format('truetype'),
    url('/fonts/Oswald-Regular.svg#Oswald-Regular') format('svg');
}
@font-face {
  font-family: 'UTMColossalis';
  src: url('/fonts/UTMColossalis.woff2') format('woff2'),
}
@font-face {
  font-family: 'UVNThangVu';
  src: url('/fonts/UVNThangVu.woff2') format('woff2'),
}
@font-face {
  font-family: 'UTMCandombe';
  src: url('/fonts/UTMCandombe.woff2') format('woff2'),
}
@font-face {
  font-family: 'Plume';
  src: url('/fonts/Plume.woff2') format('woff2'),
}
@font-face {
  font-family: 'JusticeLeague';
  src: url('/fonts/SVN-JusticeLeague.woff2') format('woff2');
}