.box {
  width: px(865);
  margin: 0 auto px(40);
  border: 1px solid #0054ff;
  background: rgba(#000519, .9);

  &__header {
    color: #ffffff;
    font-family: 'Beba';
    font-size: px(50);
    background-color: #0054ff;
    text-align: center;
    line-height: 1em;
    padding: px(14) px(20) px(15);
  }

  &__body {
    color: #ffffff;
    padding: 0 px(82);
    font-size: px(22);
    font-weight: 300;
    position: relative;

    .auth {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
      font-size: px(18);
      text-transform: uppercase;
      padding: px(15) px(20);

      a {
        text-transform: none;
        font-size: px(16);
        color: #ffcb23;
      }
    }

    &--actions {
      position: absolute;
      top: px(60);
      left: 0;
      width: 100%;
      text-align: center;
      font-size: px(22);

      a {
        color: #fff;
        display: inline-block;
        position: relative;
        padding-left: px(42);
        font-weight: 700;

        &:before {
          content: '';
          width: px(32);
          height: px(32);
          position: absolute;
          top: calc(50% - #{px(32/2)});
          left: 0;
          background: url($img+'images/check-box-blank.png') 0 0/100% 100% no-repeat;
        }

        & ~ a {
          margin-left: px(40);
        }

        &.active {
          color: #ffcb23;

          &:before {
            background: url($img+'images/check-box-tick.png') 0 0/100% 100% no-repeat;
          }
        }
      }
    }

    &--wrap-type {
      min-height: px(150);
    }

    &--note {
      min-height: px(400);
      padding-top: px(120);
      text-align: center;
      >div {
        text-align: center;
        margin-top: px(20);
        padding-top: px(38);
        border-top: 1px solid #013eba;
      }
    }

    .scrollbar-container {
      position: relative;
      width: 100%;
      height: px(457);
    }

    .ScrollbarsCustom {
      height: px(400) !important;

      &-Wrapper {
        right: 0 !important;
      }

      &-Track {
        right: -2.2rem !important;
        width: .7rem !important;
        background: none !important;
      }

      &-Thumb {
        background: $white !important;
      }
    }
  }

  &__footer {
    text-align: right;

    a {
      font-weight: 400;
      font-size: px(16);
      font-style: italic;
      color: #ffffff;
      background: #0054ff;
      display: inline-block;
      min-width: px(159);
      text-align: center;
      padding: 6px 10px;
      margin-left: 1px;

      &:hover {
        background: rgba(#0054ff, .8);
      }

      img {
        width: px(19);
        margin-right: 5px;
      }
    }
  }
}
