
.widht-pop{
    z-index: 1050;
    padding: 0;
    width:  auto;
    border:0;
    border-radius: 0;
    background-image: url($img+'images/pop-bg.png');
    background-size: 100% 100%;
    // overflow: hidden;
    max-width: 100%;
    display: inline-block;
}
.content-border{
    background-size: 100% 100%;
    background-color: transparent;
    border: none;
    padding: 0;
}
.pop-title{
    font-family: 'JusticeLeague';
    text-transform: uppercase;
    font-size: px(42);
    padding: px(10) px(15) px(5) px(15);
    color: #fff;
    text-align: center;
    text-align: center;
    margin: px(0);
    background-color: #1d3ad5;
    margin-bottom: px(50);

}

.modal {
    text-align: center;
    .close-pop {
        position: absolute;
        right: -2.08333vw;
        top: -.52083vw;
        z-index: 1050;
        // background-image: url('');
        background-size: 100% 100%;
        width: 2.08333vw;
        height: 1.875vw;
        &:hover {
            opacity: 0.75;
        }
    }
    .modal-dialog {
        @extend .widht-pop;
        .modal-content {
            @extend .content-border;
        }
        .modal-body {
            padding: 0;
        }

        .modal-header {
            .close {
                position: absolute;
                right: 10px;
                font-weight: 200;
                font-size: 4rem;
                top: 0px;
                color: #9b9b9b;
            }
        }

        .md-header {
           @extend .pop-title;
        }
    }
    &.pop-red{
        .modal-dialog {
            background-image: url($img+'images/pop-bg-red.png');
        }
    }
    .pop-body {
        img {
            max-height: 650px;
        }
    }
    &.show {
        display: block;

        .modal-backdrop {
            // background-color: rgba(35, 50, 163, 0.7);
            opacity: 0.7;
        }
    }
    &.modal-img {
        text-align: center;
        .modal-dialog {
            padding: 0;
            .modal-content {
                padding: 0;
            }
        }
    }
}
//custom class swall
.ex-sw-title{
    margin: 0 auto;
}
.ex-sw-content{
    margin-top: px(-50);
    padding-top: px(80);
}
//--end
.swal2-popup.custom-modal {
    display: block !important;
    @extend .widht-pop;
    min-width: px(450);
    padding-bottom: px(0);
    .swal2-header{
        position: relative;
        z-index: 9;
        text-align: center;
        .swal2-title {
            display: block !important;
            text-align: center;
            margin-bottom: px(18);
            text-align: center;
            background-color: #1d3ad5;
            padding: px(8) 0 px(14) 0;
            color: #fff;
            font-size: px(42);
            font-family: 'JusticeLeague';
            text-transform: uppercase;
            font-weight: normal;
            width: 100%;
        }
    }

    .swal2-actions {
        display: none !important;
    }

    .swal2-content {
        font-size: px(20);
        font-size: px(18);
        color: #000;
        padding: px(0);
        font-weight: 300;
        text-transform: uppercase;
        margin-top: px(10);
    }

    .pop-content {
        font-size: px(18);
        color: #000;
        padding: px(20);
        font-weight: 300;
        text-transform: uppercase;
        max-width: px(900);

        a {
          color: inherit;
          outline: none !important;
          font-weight: 700;
        }
    }

    &.has-btn {
        background-image: url($img+'images/pop-bg-red.png');
        padding-bottom: px(50);
        .swal2-header{
            margin-top: px(0);
        }
        .swal2-actions {
            display: block !important;
            font-size: px(20);
            .swal2-styled{
                width: px(173);
                height: px(47);
                line-height: px(47);
                background-size: 100% 100%;
                color: #fff;
                font-size: px(18);
                font-weight: 500;
                padding: 0;
                border-radius: 0;
                margin: 0 px(12);
                transition:  all linear 0.25s;
                text-transform: uppercase;
                &.swal2-cancel{
                    background-image: url($img+'images/button-blue.png');
                }
                &.swal2-confirm{
                    background-image: url($img+'images/button-red.png');
                }
                &:hover{
                    transform: scale(0.85);
                }
            }
        }
    }
    &.pop-red{
        background-image: url($img+'images/pop-bg-red.png');
    }
    &.no-header{
        .swal2-header{
            margin-top: px(30);
            .swal2-title {
                @extend .ex-sw-title;
                text-align: center;
                font-size: px(22);
                font-weight: 400;
                text-transform: uppercase;
                padding: px(15) 0;
                color: #fff;
                text-align: center;
                display: block !important;
                width: 100%;
                height:auto;
                background-image: unset;
            }
        }
        .swal2-content{
            margin-top: px(20);
        }
    }
    .swal2-close {
        text-indent: -9999px;
        display: inline-block;
        width: px(40);
        height: px(36);
        position: absolute;
        background-image: url('/images/Xbtn.png');
        background-size: cover;
        background-repeat: no-repeat;
        right: 0;
        z-index: 2;

        &:hover {
            opacity: 0.75;
            transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
        }
    }
}
