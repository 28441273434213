.login {
  text-align: center;
  padding: px(23) 0;
  min-height: px(457);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  &__header,
  &__body,
  &__footer {
    width: 100%;
  }

  &__header {
    margin-bottom: px(50);
  }

  &__body {
    margin-bottom: px(77);

    img {
      width: px(267);
    }
  }

  &__footer {
    padding-top: px(25);
    border-top: 1px solid #013eba;

    a {
      color: #589fff;
      font-style: italic;
      text-decoration: underline;
      display: inline-block;
    }
  }
}
