.lock-history {
  padding: px(23) 0;
  min-height: px(457);

  .table {
    thead {
      tr {
        th {
          text-align: center;
          border-top: 0;
          border-bottom: 2px solid #013eba;
          padding-top: 13px;
          padding-bottom: 13px;
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
          border-bottom: 1px solid #022771;
          padding-top: 11px;
          padding-bottom: 11px;
        }

        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
