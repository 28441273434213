.header {
  text-align: center;
  padding: px(50) px(20) px(71);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &__logo {
    img {
      width: px(325);
    }
  }
}
