.lock-account {
  padding: px(120) 0 px(26);
  min-height: px(457);

  .form-group {
    max-width: px(536);
    position: relative;
    padding-left: px(150);
    margin-bottom: px(34);
    margin-left: auto;
    margin-right: auto;

    label, &>a, .lock-account__send-otp {
      position: absolute;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
    }

    label {
      left: 0;
      width: px(150);
      text-align: right;
      justify-content: flex-end;
      padding-right: px(14);
      line-height: 1.2em;
    }

    .form-control {
      height: px(50);
      border-radius: 0;
      background: #001d55;
      border: 1px solid #0054ff;
      box-shadow: none;
      color: #ffffff;
      font-size: px(22);
      padding: px(3) px(10);
      letter-spacing: px(1);
      transition: all 300ms ease;

      &:focus {
        background: #011338;
      }

      &[readonly] {
        background: rgba(white, .2) !important;
        border-color: rgba(#0054ff, .5) !important;
      }
    }

    .invalid-feedback {
      position: absolute;
      top: 100%;
      left: px(150);
      font-size: px(15);
      font-style: italic;
      width: calc(100% - #{px(150)});

      &.is-phone {
        left: 0 !important;
        width: 100% !important;
        text-align: right !important;

        a {
          color: inherit !important;
        }
      }
    }

    .count-down {
      position: absolute;
      top: 100%;
      left: px(150);
      font-size: px(15);
      font-style: italic;
      margin-top: 3px;
      width: calc(100% - #{px(150)});
      text-align: right;

      &__timer {
        color: #ffcb23;
      }
    }
  }


  &__name{
    a {
      right: px(10) !important;

      img {
        width: px(31);
      }
    }
  }

  &__phone {
    padding-right: px(96);
    a {
      right: px(10) !important;

      img {
        width: px(31);
      }
    }
    .lock-account__send-otp {
      img {
        width: px(136);
      }
      right: px(-54);
      width: px(136);
      text-align: center;

      .spinner {
        margin: 0 auto;
      }
    }
  }

  &__otp {
    padding-right: px(96);
  }
  .lock-account__send-email-otp {
    position: absolute;
    right: px(-54);
    width: px(136);
    top: 0;
    text-align: center;

    .spinner {
      margin: 0 auto;
    }
  }

  &__submit {
    text-align: center;
    padding-top: px(38);
    border-top: 1px solid #013eba;

    button {
      border: 0;
      background: none;
      outline: none !important;

      img {
        width: px(267);
      }
    }
  }
}
